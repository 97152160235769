<template>
    <div class="children-attribute">
        <img src="../../../assets/imgs/mobile/children/img_shenz@2x.png" alt="兒童身高、體重、頭圍、胸圍介紹">
        <ul class="children-attribute-tabs">
            <router-link to="/children/height" class="children-attribute-tab">身高</router-link>
            <router-link to="/children/weight" class="children-attribute-tab">體重</router-link>
            <router-link to="/children/bmi" class="children-attribute-tab">BMI</router-link>
            <router-link to="/children/head_circ" class="children-attribute-tab">頭圍</router-link>
            <router-link to="/children/chest_circ" class="children-attribute-tab current">胸圍</router-link>
        </ul>
        <div class="children-attribute-content">
            <div class="children-attribute-item">
                <div style="width: 0px;height: 0px; position: relative; overflow: hidden;"><h1>小孩(兒童)胸圍正常值是多少？</h1></div>
                <p>胸圍,孩子在出生時，胸圍小於頭圍，隨著月齡的增長，胸圍逐漸趕上頭圍。一般在孩子1歲時，胸圍與頭圍相等。但現在由於普遍營養狀況較好，不少嬰兒在未滿1歲時胸圍就趕上了頭圍。影響胸圍增長的因素有:營養狀況不好，缺乏體育活動及疾病造成胸廓畸形，如:雞胸、漏斗胸等。孩子1歲後，胸圍增長明顯快於頭圍，胸圍逐漸超過頭圍。到青春期胸廓發育很快，向成人體型轉變。</p>
            </div>
        </div>

        <div class="chart">
            <div class="title">0～7歲小孩胸圍參照值(cm)</div>
            <div class="table">
                <div class="head">
                    <div class="column">年齡</div>
                    <div class="column">男孩均值</div>
                    <div class="column">女孩均值</div>
                </div>
                <div class="content">
                    <div class="row"><div class="column">初生</div><div class="column">33.32</div><div class="column">33.08</div></div>
                    <div class="row"><div class="column">1月～</div><div class="column">38.05</div><div class="column">37.2</div></div>
                    <div class="row"><div class="column">2月～</div><div class="column">40.28</div><div class="column">39.28</div></div>
                    <div class="row"><div class="column">3月～</div><div class="column">40.7</div><div class="column">40.33</div></div>
                    <div class="row"><div class="column">4月～</div><div class="column">42.51</div><div class="column">41.31</div></div>
                    <div class="row"><div class="column">5月～</div><div class="column">43.04</div><div class="column">41.85</div></div>
                    <div class="row"><div class="column">6月～</div><div class="column">43.32</div><div class="column">42.22</div></div>
                    <div class="row"><div class="column">7月～</div><div class="column">43.94</div><div class="column">42.67</div></div>
                    <div class="row"><div class="column">8月～</div><div class="column">44.18</div><div class="column">43.5</div></div>
                    <div class="row"><div class="column">9月～</div><div class="column">44.6</div><div class="column">43.56</div></div>
                    <div class="row"><div class="column">10月～</div><div class="column">45.31</div><div class="column">44.18</div></div>
                    <div class="row"><div class="column">11月～</div><div class="column">45.39</div><div class="column">44.25</div></div>
                    <div class="row"><div class="column">12月～</div><div class="column">46.35</div><div class="column">45.25</div></div>
                    <div class="row"><div class="column">15月～</div><div class="column">47.26</div><div class="column">46.28</div></div>
                    <div class="row"><div class="column">18月～</div><div class="column">48.43</div><div class="column">47.04</div></div>
                    <div class="row"><div class="column">21月～</div><div class="column">49.27</div><div class="column">48.19</div></div>
                    <div class="row"><div class="column">24月～</div><div class="column">49.83</div><div class="column">48.72</div></div>
                    <div class="row"><div class="column">27月～</div><div class="column">50.26</div><div class="column">49.25</div></div>
                    <div class="row"><div class="column">30月～</div><div class="column">51.04</div><div class="column">50.16</div></div>
                    <div class="row"><div class="column">33月～</div><div class="column">51.55</div><div class="column">50.31</div></div>
                    <div class="row"><div class="column">3.0歲～</div><div class="column">51.7</div><div class="column">50.68</div></div>
                    <div class="row"><div class="column">3.5歲～</div><div class="column">52.48</div><div class="column">51.67</div></div>
                    <div class="row"><div class="column">4.0歲～</div><div class="column">53.03</div><div class="column">52.12</div></div>
                    <div class="row"><div class="column">4.5歲～</div><div class="column">53.74</div><div class="column">52.8</div></div>
                    <div class="row"><div class="column">5.0歲～</div><div class="column">54.57</div><div class="column">53.62</div></div>
                    <div class="row"><div class="column">5.5歲～</div><div class="column">55.22</div><div class="column">54.28</div></div>
                    <div class="row"><div class="column">6.0歲～</div><div class="column">55.83</div><div class="column">55.14</div></div>
                    <div class="row"><div class="column">6.5歲～</div><div class="column">56.65</div><div class="column">55.93</div></div>
                    <div class="row"><div class="column">7.0歲</div><div class="column">57.31</div><div class="column">56.49</div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
        created: function(){
            setTimeout(() => {
                window.scrollTo(0, 860)    
            }, 0);
        }
    }
</script>

<style lang="scss" scoped>
    .children-attribute {
        margin: 0px 24px;
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: #fff;
    }

    .children-attribute > img {
        display: block;
        width: 440px;
        height: 468px;
        margin: 40px 0px 0px 32px;
    }

    .children-attribute > .children-attribute-tabs {
        margin-top: 46px;
        height: 48pxx;
        display: block;
        display: flex;
        flex-direction: row;
        margin-left: 32px;
        justify-content: flex-start;
    }

    .children-attribute > .children-attribute-tabs > .children-attribute-tab {
        font-size: 34px;
        line-height: 33px;
        height: 48px;
        color: #231F20;
        font-weight: bold;
        display: inline-block;
        margin-right: 52px;
        cursor: pointer;
    }

    .children-attribute > .children-attribute-tabs > .children-attribute-tab.current {
        color: #36C4D0;
        position: relative;
    }

    .children-attribute > .children-attribute-tabs > .children-attribute-tab.current::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0px;
        height: 4px;
        width: 12px;
        background-color: #36C4D0;
        transform: translateX(-50%);
    }
    
    .children-attribute > .children-attribute-content {
        background-color: #fff;
        font-size: 27.54px;
        line-height: 41.32px;
        font-weight: 400;
        color: #636363;
        margin: 12px 32px;
    }

    .chart {
        margin-bottom: 20px;
    }

    .chart,
    .chart > .table {
        display: flex;
        flex-direction: column;
    }

    .chart > .title {
        height: 77px;
        line-height: 77px;
        color: #242020;
        font-size: 30px;
        font-weight: bold;
        position: relative;
        padding-left: 48px;
        margin-left: 32px;
        box-sizing: border-box;
    }

    .chart > .title::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 32px;
        height: 32px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url(../../../assets/imgs/pc/children/img_copy@2x.png);;
    }

    .chart > .table > .content > .row,
    .chart > .table > .head {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        background-color: #EEF9FA;
        height: 50px;
        align-items: center;
    }

    .chart > .table > .head {
        border-bottom: 1px solid #CEE5E7;
    }

    .chart:last-child {
        margin-bottom: 70px;
    }
    
    .chart:last-child > .table > .head {
        background-color: #FFE7E7;
        border-bottom: 1px solid #E8C5C5;
    }


    .chart > .table > .content > .row {
        background-color: #fff;
    }

    .chart > .table > .content > .row:nth-child(odd) {
        background-color: #F6F7F8;
    }

    .chart > .table > .head > .column,
    .chart > .table > .content > .row > .column {
        width: 100%;
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        color: #646464;
    }

    .chart > .table > .head > .column {
        color: #242020;
        font-size: 28px;
        font-weight: bold;
    }
</style>
